import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MONTH_NAMES } from '@utils/constants';

@Injectable({
  providedIn: 'root',
})
export class DateTranslateService {
  constructor(private translateService: TranslateService) {}

  getMonthLabels(): string[] {
    const monthNamesArray: string[] = [];
    for (let i = 0; i < 12; i++) {
      monthNamesArray.push(
        this.translateService.instant(`primeng.monthNames.${i}`) ??
          MONTH_NAMES[i]
      );
    }
    return monthNamesArray;
  }
}
